import React from "react";

const Popup = (props) => {
  const closeBtnHandler = () => {
    props.closemodal(false);
  };

  return (
    <>
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="cross-btn" onClick={closeBtnHandler}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="modal-img-text">
            <img src={props.photo} alt="" />
            <div className="modal-text">{props.errorMessage}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
