import React from "react";
import Landing from "../components/Landing";
import Positions from "../components/Positions";

const Main = () => {
  return (
    <>
      <Landing />
      <Positions />
    </>
  );
};

export default Main;
