import axios from "axios";
import React, { useState, useEffect } from "react";
import Popup from "./Popup";

const Form = (props) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [course, setCourse] = useState("");
  const [college, setCollege] = useState("");
  const [semester, setSemester] = useState("");
  const [resumelink, setResumelink] = useState("");
  const [githublink, setGithublink] = useState("");
  const [portfoliolink, setPortfoliolink] = useState("");
  const [linkedinlink, setLinkedinlink] = useState("");
  const [whysellular, setWhysellular] = useState("");

  const [modaltext, setModaltext] = useState("");
  const [photo, setPhoto] = useState("missing.gif");
  const [github, setGithub] = useState(false);

  const [popup, setPopup] = useState(false);

  // GITHUB INPUT FUNCTION

  useEffect(() => {
    if (window.location.pathname === "/techform") {
      setGithub(true);
    }
  }, []);

  // SUBMIT HANDLER FUNCTION

  const submitHandler = (e) => {
    if (fullname === "") {
      setModaltext("Full Name is missing!");
      setPopup(true);
    } else if (email === "") {
      setModaltext("Email is missing!");
      setPopup(true);
    } else if (phone === "") {
      setModaltext("Phone is missing!");
      setPopup(true);
    } else if (course === "") {
      setModaltext("Course is missing!");
      setPopup(true);
    } else if (college === "") {
      setModaltext("College is missing!");
      setPopup(true);
    } else if (semester === "") {
      setModaltext("Semester is missing!");
      setPopup(true);
    } else if (linkedinlink === "") {
      setModaltext("LinkedIn Link is missing!");
      setPopup(true);
    } else if (whysellular === "") {
      setModaltext("'Why Sellular?' is missing!");
      setPopup(true);
    } else {
      e.preventDefault();
      axios.post(props.postlink, {
        fullname,
        email,
        phone,
        address,
        city,
        state,
        postalcode,
        college,
        course,
        semester,
        resumelink,
        githublink,
        portfoliolink,
        linkedinlink,
        whysellular,
      });
      setTimeout(() => {
        window.location.replace("/");
      }, 3000);
      setModaltext(
        "You have successfully submitted the form. We will get back to you soon!"
      );
      setPhoto("tick.gif");
      setPopup(true);
      setFullname("");
      setEmail("");
      setPhone("");
      setCity("");
      setState("");
      setPostalcode("");
      setCourse("");
      setCollege("");
      setSemester("");
      setResumelink("");
      setGithublink("");
      setPortfoliolink("");
      setLinkedinlink("");
      setWhysellular("");
      setAddress("");
    }
  };

  return (
    <>
      <div className="popup-modal">
        {popup && (
          <Popup errorMessage={modaltext} photo={photo} closemodal={setPopup} />
        )}
      </div>
      <div className="form-wrapper">
        <div className="form-heading-wrapper">
          {/* <div className="form-heading-container">
            <img src="sellular-logo.png" alt="sellular-logo" />
            <h1>Sellular</h1>
          </div> */}
          <div className="form-heading-wrapper-2">
            <div>{props.roles} Team Internship</div>
          </div>
        </div>
        <div className="form">
          <div className="form-topic-wrapper form-personal-info-wrapper">
            <h2>Personal Info</h2>
            <div className="user-box">
              <label htmlFor="fullname">
                Full Name<span>*</span>
              </label>
              <input
                type="text"
                name="fullname"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="Email">
                Email<span>*</span>
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="Phone">
                Phone<span>*</span>
              </label>
              <input
                type="number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="City">City</label>
              <input
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="State">State</label>
              <input
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="postalcode">Postal Code</label>
              <input
                type="number"
                name="postalcode"
                value={postalcode}
                onChange={(e) => setPostalcode(e.target.value)}
                autocomplete="off"
              />
            </div>
          </div>
          <div className="form-topic-wrapper form-college-info-wrapper">
            <h2>College Info</h2>

            <div className="user-box">
              <label htmlFor="college">
                College<span>*</span>
              </label>
              <input
                type="text"
                name="college"
                value={college}
                onChange={(e) => setCollege(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="course">
                Course<span>*</span>
              </label>
              <input
                type="text"
                name="course"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="semester">
                Semester<span>*</span>
              </label>
              <input
                type="text"
                name="semester"
                value={semester}
                onChange={(e) => setSemester(e.target.value)}
                autocomplete="off"
              />
            </div>
          </div>
          <div className="form-topic-wrapper link-wrapper">
            <h2>Links</h2>
            <div className="user-box">
              <label htmlFor="linkedinlink">
                LinkedIn Link<span>*</span>
              </label>
              <input
                type="text"
                name="linkedinlink"
                value={linkedinlink}
                onChange={(e) => setLinkedinlink(e.target.value)}
                autocomplete="off"
              />
            </div>
            <div className="user-box">
              <label htmlFor="resumelink">Resume Link</label>
              <input
                type="text"
                name="resumelink"
                value={resumelink}
                onChange={(e) => setResumelink(e.target.value)}
                autocomplete="off"
              />
            </div>
            {github && (
              <div className="user-box">
                <label htmlFor="githublink">GitHub Link</label>
                <input
                  type="text"
                  name="githublink"
                  value={githublink}
                  onChange={(e) => setGithublink(e.target.value)}
                  autocomplete="off"
                />
              </div>
            )}

            <div className="user-box">
              <label htmlFor="portfoliolink">Portfolio Link</label>
              <input
                type="text"
                name="portfoliolink"
                value={portfoliolink}
                onChange={(e) => setPortfoliolink(e.target.value)}
                autocomplete="off"
              />
            </div>
          </div>
          <div className="form-topic-wrapper">
            <h2>
              Why do you want to join Sellular?<span>*</span>
            </h2>
            <div className="user-box user-box-2">
              <textarea
                name="whysellular"
                value={whysellular}
                onChange={(e) => setWhysellular(e.target.value)}
                autocomplete="off"
                maxlength="300"
              />
            </div>
          </div>
          <div className="button-wrapper">
            <button onClick={submitHandler}>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
