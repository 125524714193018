import React from "react";

const Footer = () => {
  return (
    <>
      {/* <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-logo">
            <img
              className="footer-logo-img"
              src="main-logo.png"
              alt="sellular-logo"
            />
            <div className="footer-logo-text">
              Sellular is the most trusted way of buying and selling around your
              campus. Choose from fully verified and trusted people to buy and
              sell.
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-page-links">
              <ul>
                <li>
                  <a href="https://www.sellular.club">Home</a>
                </li>
                <li>
                  <a href="https://placex.sellular.club">PlaceX</a>
                </li>
                <li>
                  <a href="#hero-wrapper">Careers</a>
                </li>
              </ul>
            </div>
            <div className="footer-social-links">
              <div className="social-icons">
                <ul className="socialicons">
                  <li>
                    <a
                      className="discord"
                      href="https://discord.gg/fsDVF6sy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      href="/#"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="instagram"
                      href="https://www.instagram.com/sellular_networks/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkedin"
                      href="https://www.linkedin.com/company/sellularnetworks/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <footer id="footer" className="footer">
        <div className="footer-img">
          <a
            href="http://sellular.club"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="main-logo.png" alt="sellular-logo" />
          </a>
          <h2>
            <a
              href="mailto:help.sellular@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-envelope"></i> help.sellular@gmail.com
            </a>
          </h2>
        </div>
        <div className="vertical-line"></div>

        <div className="social-icons">
          <ul className="socialicons">
            <li>
              <a
                className="discord"
                href="https://discord.gg/mZhf8hTBk5"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-discord"></i>
              </a>
            </li>
            <li>
              <a className="twitter" href="/#" target="_blank" rel="noreferrer">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                className="instagram"
                href="https://www.instagram.com/sellular_networks/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/sellularnetworks/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
