/* eslint-disable */
import React, { useRef } from "react";

export default function Navbar() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <div>
      <header>
        <a href="https://sellular.club">
          <div className="logoText">
            <img src="sellular-logo.png" alt="logo" className="Logo-navbar" />
            <strong>SELLULAR</strong>
          </div>
        </a>
        <nav ref={navRef}>
          <div className="nav-links">
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <i class="fa-solid fa-xmark"></i>
            </button>
            <a
              className="text-white nav-link mt-2"
              href="/"
              onClick={showNavbar}
            >
              Careers
            </a>
            <a className="text-white nav-link mt-2" href="#positions-wrapper">
              Positions
            </a>
            <a
              className="text-white nav-link mt-2"
              href="https://placex.sellular.club"
              target="_blank"
              rel="noreferrer"
              onClick={showNavbar}
            >
              PlaceX
            </a>
            {/* <a
              className="text-white nav-link mt-2"
              href="#FaqSection"
              onClick={showNavbar}
            >
              FAQ
            </a> */}
          </div>
        </nav>
        <div className="outNav justify-content-end pe-2">
          <button className="nav-btn nav-ham-btn" onClick={showNavbar}>
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
      </header>
    </div>
  );
}
