import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./components/styles.css";
// import Form from "./components/Form";
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Form from "./components/Form";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
        <Routes>
          <Route
            path="/techform"
            element={
              <Form
                roles="Tech"
                postlink="https://sheetdb.io/api/v1/bu2qryinofokj"
              />
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/growthform"
            element={
              <Form
                roles="Growth"
                postlink="https://sheetdb.io/api/v1/mg3wndo9jfit6"
              />
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/communityform"
            element={
              <Form
                roles="Community"
                postlink="https://sheetdb.io/api/v1/x8nt5nq3gzfg3"
              />
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/socialform"
            element={
              <Form
                roles="Social Media"
                postlink="https://sheetdb.io/api/v1/9wm63ij3usxph"
              />
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
