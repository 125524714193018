import React from "react";

const Hero = () => {
  return (
    <>
      <div id="hero-wrapper" className="hero-wrapper">
        <div class="hero">
          <div class="diagonal-hero-bg">
            <div class="stars">
              <div class="small"></div>
              <div class="medium"></div>
              <div class="big"></div>
            </div>
          </div>
        </div>
        <div className="hero-heading-wrapper">
          <div className="hero-heading">Internships @Sellular</div>
          <div className="hero-quote">
            Saving Bucks and Enhancing College Life for Students
            <div>-Sellular</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
