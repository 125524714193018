import React from "react";

const Positions = () => {
  return (
    <>
      <div id="positions-wrapper" className="positions-wrapper">
        <div className="positions-heading">
          <div>
            Join the Rocketship Now!
            <img src="rocketship.gif" alt="rocketship" />
          </div>
        </div>
        <div className="positions-main">
          <div className="positions-main-heading">Open Positions</div>
          <div className="positions-list-wrapper">
            <ul className="positions-list">
              <li className="positions-list-element">
                <div>Tech Team</div>
                <button>
                  <a href="/techform">Apply</a>
                </button>
              </li>
              <hr />
              <li className="positions-list-element">
                <div>Growth Team</div>
                <button>
                  <a href="/growthform">Apply</a>
                </button>
              </li>
              <hr />
              <li className="positions-list-element">
                <div>Community Team</div>
                <button>
                  <a href="/communityform">Apply</a>
                </button>
              </li>
              <hr />
              <li className="positions-list-element">
                <div>Social Media Team</div>
                <button>
                  <a href="/socialform">Apply</a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Positions;
